import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HTTPURL } from "../../../constants/Matcher";
import profile from "./../../../image/profile.png";
import html2pdf from "html2pdf.js";
import "./../../../styles/OrderDetails.css";
import { async } from "q";
import { toast } from "react-toastify";
import BookingFlow from "../../BookingFlow";

const OrderDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const stutuses = [
    { ids: [], title: "New" },
    { ids: [0], title: "Picked" },
    { ids: [0, 8], title: "Washed" },
    { ids: [0, 1, 8], title: "Dried" },
    { ids: [0, 1, 2, 8], title: "Folded" },
    { ids: [0, 1, 2, 3, 8], title: "Packed" },
    { ids: [0, 1, 2, 3, 4, 8], title: "Done" },
  ];

  const order_statuses = [
    "New",
    "Washed",
    "Dried",
    "Folded",
    "Packed",
    "-",
    "Complete",
    "-",
    "Picked",
  ];
  const [orderDetails, setOrderDetails] = useState(null);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(0);
  const [weight, setWeight] = useState([0]);
  const [isLoader, setIsLoader] = useState(false);
  const [estimatedLoads, setEstimatedLoads] = useState(0);
  const [price, setPrice] = useState(0);
  const [minOrder, setMinOrder] = useState(0);
  const [totalLoads, setTotalLoads] = useState(0);
  const [bin, setBin] = useState("");
  const [paidAmount, setPaidAmount] = useState(0);
  const [bookingStatusDetail, setBookingStatusDetail] = useState(null);

  useEffect(() => {
    getOrderDetails();
  }, []);

  const getOrderDetails = () => {
    axios
      .get(HTTPURL + `/api/get-order-detail/${params.order_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response?.data?.status) {
          let data = response?.data?.data;
          setBookingStatusDetail(response?.data?.bookingStatusdetail);
          setOrderDetails(data);
          setEstimatedLoads(data.estimated_loads);
          setPrice(data.price);
          setMinOrder(data.min_order);
          setTotalLoads(data.total_loads);
          setBin(data.bin);
          setPaidAmount(data.paid_amount);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const detailsPage = (id) => {
    navigate("/customer-details/" + id);
  };

  const addNewWeight = (e) => {
    var tmp = Object.values({ ...weight });
    if (tmp.length < 20) {
      tmp.push(0);
      setWeight(tmp);
    } else {
      toast.error("Max 20 Wt. scale reading allowed.");
    }
  };

  const updateStatus = (status) => {
    if (status != 6 || orderDetails?.paid_amount > 0) {
      const form = new FormData();
      form.append("booking_id", orderDetails?.id);
      form.append("status", status);

      axios
        .post(HTTPURL + "/api/update-booking-status/", form, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            getOrderDetails();
          } else {
            toast.error(response?.data?.message);
          }
        });
    } else {
      setSelectedOrderStatus(6);
    }
  };

  const handleCompletedStatus = async () => {
    setIsLoader(true);

    var consent = true;
    const form = new FormData();
    form.append("booking_id", orderDetails?.id);
    form.append("status", 6);

    if (orderDetails?.unit == 2 && orderDetails.paid_amount <= 0) {
      const tmp_weight = weight.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue),
        0
      );
      form.append("weight", tmp_weight);

      let total_amount = orderDetails?.min_order * orderDetails?.price;
      if (tmp_weight >= orderDetails?.min_order) {
        total_amount = tmp_weight * orderDetails?.price;
      }

      if (orderDetails.credit < total_amount) {
        const balance = total_amount - orderDetails.credit;
        const str =
          "Customer credit ( USD " +
          orderDetails.credit +
          ") is low than his bill( USD" +
          total_amount +
          ") for " +
          tmp_weight +
          "lbs" +
          " An amount of USD" +
          balance +
          " will be deducted from customer credit card. Are you sure, you want to proceed.";
        if (!window.confirm(str)) {
          consent = false;

          setIsLoader(false);
        }
      }
    }

    if (consent) {
      axios
        .post(HTTPURL + "/api/update-booking-status/", form, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            getOrderDetails();
            setIsLoader(false);
          } else {
            toast.error(response?.data?.message);
          }
        });
    }
  };

  const handleUpdateOrder = async () => {
    if (orderDetails.paid_amount > 0 && (paidAmount <= 0 || totalLoads <= 0)) {
      toast.error(
        "This Order is already charged, Please enter correct charged amount & Actual Weight/Bags"
      );
    } else if (orderDetails.paid_amount <= 0 && paidAmount > 0) {
      toast.error(
        "This Order is not yet charged, Charge amount must be zero. Please charge first."
      );
    } else if (price <= 0 || estimatedLoads <= 0 || minOrder <= 0) {
      toast.error("Price, Min Weight and Estimated Weigth are required");
    } else {
      setIsLoader(true);
      const form = new FormData();
      form.append("booking_id", orderDetails?.id);
      form.append("price", price);
      form.append("min_order", minOrder);
      form.append("estimated_loads", estimatedLoads);
      form.append("total_loads", totalLoads);
      form.append("bin", bin);
      form.append("paid_amount", paidAmount);

      axios
        .post(HTTPURL + "/api/update-order/", form, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            getOrderDetails();
            setIsLoader(false);
          } else {
            toast.error(response?.data?.message);
          }
        });
    }
  };

  return (
    <div className="container-fluid px-0" id="order_detail_cont">
      <div className="right-nav py-3 px-2">
        <h3>Order Details #{orderDetails?.id}</h3>

        <div class="row mt-3">
          <div class="col-lg-6 mb-3">
            <div className="card  rounded-4">
              <h5 className="card-header">Order Details</h5>
              <div className="card-body form-group">
                <div className="row">
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Order ID:</label>
                    {orderDetails?.id}
                  </div>
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Category:</label>
                    {orderDetails?.category_title}
                  </div>
                </div>
                <div className="row">
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Pickup Address:</label>
                    {orderDetails?.pickup_address}
                  </div>
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Delivery Address:</label>
                    {orderDetails?.delivery_address}
                  </div>
                </div>
                <div className="row">
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Price:</label>
                    {orderDetails?.price}
                    {orderDetails?.unit == 1 ? "/bag" : "/lb"}
                  </div>
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Min Wt./Bags:</label>
                    {orderDetails?.min_order}
                    {orderDetails?.unit == 1 ? "bags" : "lbs"}
                  </div>
                </div>
                <div className="row">
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Estimated Wt./Bags:</label>
                    {orderDetails?.estimated_loads}
                    {orderDetails?.unit == 1 ? "bags" : "lbs"}
                  </div>
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Actual Wt./Bags:</label>
                    {orderDetails?.total_loads == 0
                      ? "Not Weighed Yet"
                      : orderDetails?.total_loads}
                    {orderDetails?.unit == 1 ? "bags" : "lbs"}
                  </div>
                </div>
                <div className="row">
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Order Status:</label>
                    {order_statuses[parseInt(orderDetails?.order_status)]}
                  </div>
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Bin #:</label>
                    {orderDetails?.bin}
                  </div>
                </div>
                <div className="row">
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Payment Status:</label>
                    {orderDetails?.paid_amount == 0 ? "Not Paid" : "Paid"}
                  </div>
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Amount Paid:</label>USD{" "}
                    {orderDetails?.paid_amount}
                  </div>
                </div>
                <div className="row">
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">
                      Driver Instructions:
                    </label>
                    {orderDetails?.driver_instructions}
                  </div>
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">
                      Folder Instructions:
                    </label>
                    {orderDetails?.folder_instructions}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 mb-3">
            <div className="card  rounded-4">
              <h5 className="card-header">Customer Information</h5>
              <div className="card-body form-group">
                <div className="row">
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">User ID:</label>
                    {orderDetails?.user_id}
                  </div>
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Name:</label>
                    {orderDetails?.name}
                  </div>
                </div>
                <div className="row">
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Email:</label>
                    <a target="_blank" href={"mailto:" + orderDetails?.email}>
                      {orderDetails?.email}
                    </a>
                  </div>
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Phone:</label>
                    <a target="_blank" href="tel:{orderDetails?.mobile}">
                      {orderDetails?.mobile}
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Pickup Address:</label>
                    {orderDetails?.pickup_address}
                  </div>
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Delivery Address:</label>
                    {orderDetails?.delivery_address}
                  </div>
                </div>

                <div className="row">
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Credit:</label>USD
                    {orderDetails?.credit}
                  </div>
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">Square ID:</label>
                    {orderDetails?.customer_id ? "Yes, Saved" : "Not Saved Yet"}
                  </div>
                </div>

                <div className="row">
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">
                      Driver Instructions:
                    </label>
                    {orderDetails?.driver_instructions}
                  </div>
                  <div class="col-sm-6 font-black-medium">
                    <label className="d-inline me-2">
                      Folder Instructions:
                    </label>
                    {orderDetails?.folder_instructions}
                  </div>
                </div>
                <div className="row">
                  <div class="col-sm-12 font-black-medium">
                    <label className="d-inline me-2">How I found Weclea:</label>
                    {orderDetails?.comment}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 mb-3">
            <div className="card  rounded-4 ">
              <h5 className="card-header">Update Order Status</h5>

              <div className="row">
                <div className="col-lg-12 mt-4 text-center">
                  <div className=" mt-2">
                    {orderDetails?.order_status == 0 && (
                      <button
                        onClick={(e) => updateStatus(8)}
                        class="btn btn-lg btn-info me-1 mb-1"
                      >
                        Order Pickup
                      </button>
                    )}
                    {[0, 8].includes(orderDetails?.order_status) && (
                      <button
                        onClick={(e) => updateStatus(1)}
                        class="btn btn-lg btn-info me-1 mb-1"
                      >
                        Washed
                      </button>
                    )}
                    {[0, 8, 1].includes(orderDetails?.order_status) && (
                      <button
                        onClick={(e) => updateStatus(2)}
                        class="btn btn-lg btn-info me-1 mb-1"
                      >
                        Dried
                      </button>
                    )}
                    {[0, 8, 1, 2].includes(orderDetails?.order_status) && (
                      <button
                        onClick={(e) => updateStatus(3)}
                        class="btn btn-lg btn-info me-1 mb-1"
                      >
                        Folded
                      </button>
                    )}
                    {[0, 8, 1, 2, 3].includes(orderDetails?.order_status) && (
                      <button
                        onClick={(e) => updateStatus(4)}
                        class="btn btn-lg btn-info me-1 mb-1"
                      >
                        Packed
                      </button>
                    )}
                    {[0, 8, 1, 2, 3, 4].includes(
                      orderDetails?.order_status
                    ) && (
                      <button
                        onClick={(e) => updateStatus(6)}
                        class="btn btn-lg btn-info me-1 mb-1"
                      >
                        Completed
                      </button>
                    )}
                  </div>
                  {(selectedOrderStatus === 6 ||
                    orderDetails?.order_status === 4) &&
                    orderDetails?.paid_amount === 0 && (
                      <>
                        <div className="form-group mt-3">
                          <label>
                            {orderDetails?.unit == 2
                              ? "Weighing Scale Readings (Total Wt.)"
                              : "Total Bags"}
                          </label>
                          {weight?.map((extra, index) => (
                            <input
                              type="number"
                              name="weight[]"
                              onChange={(e) =>
                                setWeight(
                                  Object.values({
                                    ...weight,
                                    [index]: e.target.value,
                                  })
                                )
                              }
                              className="form-control extra-load"
                            />
                          ))}
                          {orderDetails?.unit == 2 && (
                            <>
                              <butoon
                                class="d-inline-block btn-lg btn-success"
                                onClick={addNewWeight}
                              >
                                <i class="fa fa-plus"></i>
                              </butoon>

                              <label className="mt-2">
                                Total:{" "}
                                {weight.reduce(
                                  (accumulator, currentValue) =>
                                    accumulator + Number(currentValue),
                                  0
                                )}
                              </label>
                            </>
                          )}
                        </div>
                        <div className=" text-center mb-3">
                          <button
                            type="button"
                            className="btn btn-lg btn-success"
                            onClick={handleCompletedStatus}
                            disabled={isLoader}
                          >
                            Complete Order
                            {isLoader ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              ""
                            )}
                          </button>
                        </div>
                      </>
                    )}
                </div>
              </div>

              <div className="track m-1">
                <ul className="track_item ">
                  {orderDetails &&
                    stutuses.map((stutus) => (
                      <li
                        className={`${
                          stutus.ids.includes(orderDetails?.order_status)
                            ? ""
                            : "active"
                        }`}
                      >
                        <div
                          className={`check_icon ${
                            stutus.ids.includes(orderDetails?.order_status)
                              ? "uncheck"
                              : ""
                          }`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="8"
                            viewBox="0 0 10 8"
                            fill="none"
                          >
                            <path
                              d="M1 3.85714L3.5 6.35714L8.5 1"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="pick_title pt-3 pb-0 m-0">
                          {stutus.title}
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-lg-12 mb-3">
            <div className="card  rounded-4">
              <h5 className="card-header ">
                <label class="lh-lg fw-bold">Order Details</label>
                <button
                  type="button"
                  className="btn btn-lg btn-success float-end "
                  onClick={handleUpdateOrder}
                  disabled={isLoader}
                >
                  Save Order
                  {isLoader ? (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    ""
                  )}
                </button>
              </h5>
              <div className="card-body form-group">
                <div className="row ">
                  <div class="col-sm-6 mb-2">
                    <div class="row align-items-center">
                      <div class="col-xl-3 col-lg-4">
                        <label className="">Price:</label>
                      </div>
                      <div class="col-auto">
                        <input
                          className="form-control"
                          type="text"
                          name="price"
                          id="price"
                          value={price}
                          onChange={(e) => {
                            setPrice(e.target.value);
                          }}
                        />
                      </div>
                      <div class="col-auto">
                        {orderDetails?.unit == 1 ? "/bag" : "/lb"}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 mb-2">
                    <div class="row align-items-center">
                      <div class="col-xl-3 col-lg-4">
                        <label className="">Min Wt./Bags:</label>
                      </div>
                      <div class="col-auto">
                        <input
                          className="form-control"
                          type="text"
                          name="min_order"
                          id="min_order"
                          value={minOrder}
                          onChange={(e) => {
                            setMinOrder(e.target.value);
                          }}
                        />
                      </div>
                      <div class="col-auto">
                        {orderDetails?.unit == 1 ? "bags" : "lbs"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-sm-6  mb-2">
                    <div class="row align-items-center">
                      <div class="col-xl-3 col-lg-4">
                        <label className="">Estimated Wt.</label>
                      </div>
                      <div class="col-auto">
                        <input
                          className="form-control"
                          type="text"
                          name="estimated_load"
                          id="estimated_load"
                          value={estimatedLoads}
                          onChange={(e) => {
                            setEstimatedLoads(e.target.value);
                          }}
                        />
                      </div>
                      <div class="col-auto">
                        {orderDetails?.unit == 1 ? "bags" : "lbs"}
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6 mb-2">
                    <div class="row align-items-center">
                      <div class="col-xl-3 col-lg-4">
                        <label className="">Actual Wt.</label>
                      </div>
                      <div class="col-auto">
                        <input
                          className="form-control"
                          type="text"
                          name="total_loads"
                          id="total_loads"
                          value={totalLoads}
                          onChange={(e) => {
                            setTotalLoads(e.target.value);
                          }}
                        />
                      </div>

                      <div class="col-auto">
                        {orderDetails?.unit == 1 ? "bags" : "lbs"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div class="col-sm-6 mb-2">
                    <div class="row align-items-center">
                      <div class="col-xl-3 col-lg-4">
                        <label className="">Bin #:</label>
                      </div>
                      <div class="col-auto">
                        <input
                          className="form-control "
                          type="text"
                          name="total_loads"
                          id="total_loads"
                          value={bin}
                          onChange={(e) => {
                            setBin(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 mb-2">
                    <div class="row align-items-center">
                      <div class="col-xl-3 col-lg-4">
                        <label className="">Amount Paid:</label>
                      </div>
                      <div class="col-auto">
                        <input
                          className="form-control"
                          type="text"
                          name="paid_amount"
                          id="paid_amount"
                          value={paidAmount}
                          onChange={(e) => {
                            setPaidAmount(e.target.value);
                          }}
                        />
                      </div>
                      <div class="col-auto">USD</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row my-5">
          <div class="col-lg-12 mb-3">
            <div className="card  rounded-4">
              <h5 className="card-header ">
                <label class="lh-lg fw-bold">Order Outline</label>
              </h5>
              <div className="card-body form-group">
                <div className="row px-4 ">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-picking-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-picking"
                        type="button"
                        role="tab"
                        aria-controls="pills-picking"
                        aria-selected="true"
                      >
                        Picked
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-attendant_checking-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-attendant_checking"
                        type="button"
                        role="tab"
                        aria-controls="pills-attendant_checking"
                        aria-selected="true"
                      >
                        Attendant Checking
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-Washed-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-Washed"
                        type="button"
                        role="tab"
                        aria-controls="pills-Washed"
                        aria-selected="false"
                      >
                        Washed
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-dry-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-dry"
                        type="button"
                        role="tab"
                        aria-controls="pills-dry"
                        aria-selected="false"
                      >
                        Dried
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-folded-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-folded"
                        type="button"
                        role="tab"
                        aria-controls="pills-folded"
                        aria-selected="false"
                      >
                        Folded
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-packing-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-packing"
                        type="button"
                        role="tab"
                        aria-controls="pills-packing"
                        aria-selected="false"
                      >
                        Packed
                      </button>
                    </li>
                  </ul>
                  <BookingFlow bookingStatusDetail={bookingStatusDetail}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
